<template>
  <div class="catalogues">
    
    <div class="catalogues__actions">
        <CreateCatalogueButton @showModal="showModal"/>
        <CataloguesList/>
    </div>

    <ModalCatalogues
        @closeModal="showModal"
        v-show="isActiveModal"    
    />

  </div>
</template>

<script>
export default {
    name: 'Catalogues',
    components: {
        CreateCatalogueButton: () => import('./createCatalogueButton.vue'),
        ModalCatalogues: () => import('./createCatalogueModal.vue'),
        CataloguesList: () => import('./catalogueList.vue')
    },
    data() {
        return {
            isActiveModal: false
        }
    },
    methods: {
        showModal() {
            this.isActiveModal = !this.isActiveModal
        }
    },
}
</script>

<style>

</style>
